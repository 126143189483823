<template>
    <div id="animate-bg" style="background: transparent">
        <svg version="1.1" baseProfile="basic" id="planes" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2893.1 1910.9"
             xml:space="preserve">
                <defs>
                <mask id="bg--cut-off1">
                    <path id="bg--path1" className="bg--path" fill="none" stroke="white" strokeWidth="4"
                          d="M-65.5,1584.4
	c182.1-9.9,208.6-20.5,345.9-58.6c887.4-246.3,480.8-1331-98.8-1037.8C-100.9,630.9-98.1,946.5-18.6,1121.2
	c218.7,480.8,1420.7,815.4,1938.2,587.8c467-216.3,587-876.4,617.2-1124.5"/>
                </mask>

                    <mask id="bg--cut-off2">
                    <path id="bg--path2" className="bg--path" fill="none" stroke="white" strokeWidth="4"
                          d="M-79.9,1754.7
	c131.1,18,1150.7,148.5,1531.6,47.8c257.2-86.5,428.7-167.2,561.5-302.3c102.2-119.6,209.1-348.9,189.2-607.8
	C2182,627.2,1968,303.9,1615.6,278.1c-333.7-24.5-616.1,230.6-664.1,496.7c-68.6,380.1,357,692.7,671.6,785.1
	c370.1,108.7,788.7-28.2,1087.9-343.8"/>
                </mask>

            </defs>
            <path id="path-1" mask="url(#bg--cut-off1)"
                  style="fill:none;stroke:#999999;stroke-miterlimit:10;stroke-dasharray:12.11,12.11;" d="M-65.5,1584.4
	c182.1-9.9,208.6-20.5,345.9-58.6c887.4-246.3,480.8-1331-98.8-1037.8C-100.9,630.9-98.1,946.5-18.6,1121.2
	c218.7,480.8,1420.7,815.4,1938.2,587.8c467-216.3,587-876.4,617.2-1124.5"/>
            <path id="path-2" mask="url(#bg--cut-off2)"
                  style="fill:none;stroke:#999999;stroke-miterlimit:10;stroke-dasharray:12.11,12.11;" d="M-79.9,1754.7
	c131.1,18,1150.7,148.5,1531.6,47.8c257.2-86.5,428.7-167.2,561.5-302.3c102.2-119.6,209.1-348.9,189.2-607.8
	C2182,627.2,1968,303.9,1615.6,278.1c-333.7-24.5-616.1,230.6-664.1,496.7c-68.6,380.1,357,692.7,671.6,785.1
	c370.1,108.7,788.7-28.2,1087.9-343.8"/>
            <g>
                    <g id="plane-1">
	<path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;" d="M112.4,3.6
		c-0.4,0.6-1.2,1.7-1.7,2.3c-0.7,0.8-1.3,1.7-1.8,2.6c-0.6,0.8-6.4,9.2-7.7,11.3c-0.6,1-3.5,5.6-4.3,6.8c-0.6,1.1-1.4,2.2-2.2,3.2
		c-0.7,0.8-1.3,1.8-1.8,2.7c-1.3,2.4-3.1,4.6-4.5,6.9C87,41.7,74.2,59.8,73.9,60.5"/>
                        <polyline
                                style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                                points="112.4,3.6
		112.4,3.6 112.4,3.6 	"/>
                        <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                              d="M51.7,45.5
		c-5.4,4.2-9.7,7.1-15,11.5"/>
                        <path style="fill:none;stroke:#D3D3D3;stroke-width:0.25;stroke-miterlimit:10;" d="M102.2,17.8c-3.1-2-6.2-4.7-9.4-6.2 M92.7,11.6
		c-0.6,7.1-0.1,15.2-0.9,22.2 M102.4,17.8c-10.6,0.8-19.5,1-30.6,1.9 M71.7,19.7C73,33.9,73.2,46,72.6,60.2 M91.8,33.8
		C73.7,33.9,61,33.5,41,34.6 M92,33.9c-1.3-1.4-2.7-2.5-4.2-3.8c-2.3-1.8-4.7-3.2-7-4.9c-1.7-1.2-3.5-2.5-5.4-3.6
		c-1.2-0.7-2.5-1.5-3.7-2.1"/>
                        <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                              d="M112.1,3.6
		c-2.9,0.4-7,1.2-9.9,1.6c-4,0.5-20,2.8-24.3,3.4c-3.7,0.5-7.5,1.2-11.1,1.7c-4.1,0.5-8.3,0.9-12.5,1.6c-3.6,0.6-7.2,1.1-10.9,1.4
		c-1.8,0.2-8.9,1.3-10.7,1.5C28.9,15.3,4.4,18.5,2.3,19"/>
                        <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                              d="M36,56.7
		c-0.1-0.5,2.8-19.7,2.8-20 M28.4,28.8c0.3,0.9,6.8,27.1,7,27.8 M40.7,34.3c0,0,2.2-0.9,4.1-1.8c2.8-1.4,5.5-2.9,8.4-4.3
		c2.9-1.4,6-2.7,8.9-4.1c4.9-2.2,5.9-2.8,9.3-4.3c2.7-1.2,5.6-2.2,8.4-3.2c2.4-0.9,4.6-1.8,7-2.7c5.1-1.9,9.8-3.8,14.9-5.7
		c2.7-1.1,6.2-2.5,9.2-3.8l1.3-0.5l0.3-0.1 M40.6,36.2c1,0.8,9.5,7.5,10.6,8.6c0.2,0.2,0.5,0.4,0.7,0.6c1,0.9,2.2,1.8,3.2,2.8
		c1.2,1.1,15.1,12.3,16.1,12.9"/>
                        <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                              d="M2.1,20.2
		c1.8,0.5,22.5,5.9,24.2,6.5"/>
                        <path style="fill:none;stroke:#C6C5C5;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;"
                              d="M90.4,10.4
		c-2.7-1.2-5.4-2-8.3-2.6 M82.1,7.9c-5.8,2.3-9.1,5.2-14.3,8.9 M90.4,10.4c-11,1-25,0.2-36.2,1.1"/>
                        <path style="fill:none;stroke:#C6C5C5;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;"
                              d="M67.7,16.9
		c-2.5,3.3-2.1,3-4.9,6.6 M49.6,30c-13.3-1.7-5.7-0.8-19.9-2.8 M54.2,11.7c0.3-0.1-15.4,6.6-25,13.6 M67.7,16.8
		c-22.7,0.9-43.1-0.4-65.3,2.3 M67.7,16.6c-4.5-2.4-9.5-3.7-13.8-4.9 M67.9,16.9c0.1,1.8,0.1,2.8,0.1,4.2 M77.3,17.4
		c-2.2-0.1-6.5-0.1-9.4-0.6 M90.4,10.4c-0.1,0.6,0,1.3,0,2"/>
                        <path style="fill:none;stroke:#C6C5C5;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;"
                              d="M90.4,10.4
		c-1.3,1.1-2.5,2.2-3.5,3.4"/>
                        <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="40.2,33.5 38.8,33.2 37.5,33.9
		37.2,35.3 38,36.5 39.4,36.8 40.6,36.2 41,34.7 	"/>
                        <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="29.1,25.3 27.7,25 26.4,25.8 26.1,27.2
		26.9,28.4 28.4,28.8 29.6,28 30,26.6 	"/>
                        <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="1.7,17.8 0.2,17.4 -1,18.2 -1.4,19.7
		-0.7,20.9 0.8,21.3 2,20.5 2.3,19.1 	"/>
                        <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="36.7,56.9 35.3,56.6 34,57.3 33.7,58.7
		34.4,60 35.8,60.3 37.1,59.6 37.4,58.2 	"/>
                        <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="73.9,60.5 72.5,60.1 71.2,60.9
		70.9,62.3 71.5,63.6 73,64 74.3,63.2 74.6,61.8 	"/>
                        <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                              d="M112.4,3.6
		c-0.4,0.2-0.9,0.5-1.6,0.7c-3.4,1.3-9.5,3.2-11.5,3.7c-6.5,1.8-13.2,3.5-19.6,5.5c-3.3,1.1-6.4,1.8-9.8,2.8c-3.1,0.9-6,1.5-9.2,2.2
		c-2.8,0.6-5.6,1.1-8.4,1.8c-2.7,0.6-5.3,1.7-7.8,2.5c-3.2,1-6.5,1.4-9.7,2.4c-1.1,0.3-3.8,1.2-4.9,1.5"/>
</g>
                <g id="plane-2">
	<path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;" d="M163.2,4.4
		c-0.5,0.9-1.5,2.7-2.1,3.6c-0.9,1.2-1.6,2.6-2.3,3.9c-0.8,1.3-8,14.1-9.7,17.3c-0.8,1.5-4.3,8.5-5.2,10.5c-0.8,1.7-1.8,3.3-2.8,4.9
		c-0.9,1.3-1.6,2.8-2.2,4.2c-1.6,3.6-3.8,7-5.5,10.5c-1.7,3.6-17.7,31.3-18.1,32.4"/>
                    <polyline
                            style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                            points="163.2,4.4
		163.2,4.4 163.2,4.4 	"/>
                    <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                          d="M81.3,73
		c-7.2,6.8-13.1,11.5-20.1,18.6"/>
                    <path style="fill:none;stroke:#D3D3D3;stroke-width:0.25;stroke-miterlimit:10;"
                          d="M150.3,26.3c-4.8-2.4-9.5-6-14.4-7.7M135.9,18.6c0.1,10.4,1.9,21.9,1.7,32.1 M150.7,26.3c-15.2,2.6-28,4.1-43.9,6.9 M106.6,33.1c3.8,20.3,5.7,37.7,6.8,58.3M137.6,50.8c-26.2,2.7-44.5,3.9-73.2,8.2 M137.8,50.9c-2-1.8-4.2-3.3-6.5-4.8c-3.5-2.3-7.2-4-10.8-6.1c-2.7-1.6-5.4-3.1-8.2-4.4c-1.8-0.8-3.9-1.8-5.7-2.6"/>
                    <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                          d="M162.7,4.4
		c-4.1,1-10,2.6-14.1,3.6c-5.7,1.3-28.5,6.7-34.6,8.1c-5.3,1.2-10.6,2.7-15.9,3.9c-5.9,1.3-11.9,2.4-17.8,4
		c-5.2,1.3-10.3,2.5-15.5,3.5c-2.5,0.5-12.6,3.1-15.2,3.7c-5.5,1.4-40,9.3-43.3,10.3"/>
                    <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                          d="M60.2,91.4
		c-0.1-0.7,1.3-28.7,1.3-29.3 M45.3,52.1c0.6,1.3,13.5,38.1,13.8,39.2 M63.9,58.3c0,0,3-1.5,5.6-3.1c3.8-2.4,7.6-5,11.4-7.3
		c4-2.5,8.2-4.7,12.3-7.1c6.7-3.8,8.1-4.8,12.9-7.5c3.8-2.1,7.8-3.9,11.7-5.8c3.3-1.5,6.5-3.2,9.7-4.8c7-3.5,13.6-6.8,20.7-10.3
		c3.8-1.9,8.6-4.5,12.8-6.8l1.8-1l0.4-0.2 M64,61.1c1.7,1,14.7,9.5,16.4,10.9c0.3,0.3,0.7,0.5,1.1,0.8c1.7,1.2,3.4,2.3,5,3.5
		c1.9,1.4,23.4,15.6,25,16.4"/>
                    <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                          d="M6.3,43.2
		c2.6,0.5,33.2,5.5,35.7,6"/>
                    <path style="fill:none;stroke:#C6C5C5;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;"
                          d="M132.3,17.3
		c-4-1.4-8.1-2.2-12.3-2.6 M120,14.7c-8.2,4.1-12.4,8.9-19.5,14.7 M132.3,17.3c-15.7,2.9-36,3.7-52,6.6"/>
                    <path style="fill:none;stroke:#C6C5C5;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;"
                          d="M100.5,29.7
		c-3.1,5.1-2.6,4.6-6.2,10.2 M76.2,51c-19.4-0.6-8.3-0.3-29-1.4 M80.3,23.9C80.7,23.7,58.9,35.5,46,47 M100.5,29.5
		c-32.5,4.4-62.1,5.2-94,12.2 M100.5,29.2c-6.8-2.8-14.2-4-20.6-5.2 M100.8,29.6c0.4,2.7,0.5,4.1,0.7,6 M114.4,29.1
		c-3.1,0.1-9.4,0.6-13.6,0.5 M132.3,17.3c0,0.9,0.1,1.9,0.2,2.8"/>
                    <path style="fill:none;stroke:#C6C5C5;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;"
                          d="M132.3,17.3
		c-1.7,1.7-3.2,3.5-4.7,5.4"/>
                    <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="63.1,57.3 61,57 59.3,58.2 59,60.3
		60.2,62 62.3,62.3 64,61.1 64.4,59 	"/>
                    <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="46,47 43.9,46.7 42.2,48 41.9,50.1
		43.2,51.8 45.3,52.1 47,50.8 47.3,48.7 	"/>
                    <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="5.3,40 3.2,39.6 1.5,40.9 1.2,43
		2.4,44.7 4.5,45 6.2,43.8 6.5,41.7 	"/>
                    <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="61.2,91.6 59.1,91.3 57.4,92.5
		57.1,94.6 58.3,96.3 60.4,96.6 62.1,95.4 62.4,93.3 	"/>
                    <polygon style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-miterlimit:10;" points="115.3,91.7 113.2,91.4 111.5,92.7
		111.2,94.8 112.4,96.5 114.5,96.8 116.2,95.5 116.6,93.4 	"/>
                    <path style="fill:none;stroke:#6B6B6B;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;"
                          d="M163.2,4.4
		c-0.5,0.3-1.3,0.8-2.2,1.2c-4.7,2.3-13.3,5.9-16.1,6.9c-9.1,3.5-18.5,6.8-27.5,10.6c-4.6,1.9-9,3.6-13.7,5.3c-4.3,1.6-8.6,3-13,4.4
		c-3.9,1.2-7.9,2.3-11.8,3.7c-3.9,1.3-7.3,3.2-11,4.7c-4.5,1.8-9.3,2.9-13.7,4.8c-1.5,0.6-5.4,2.2-6.9,2.8"/>
    </g>
                </g>
            </svg>
    </div>
</template>

<script>
	import gsap, { MotionPathPlugin, SplitText, DrawSVGPlugin } from 'gsap/all'

	gsap.registerPlugin(MotionPathPlugin)
	gsap.registerPlugin(SplitText)
	gsap.registerPlugin(DrawSVGPlugin)


	export default {
		name: 'AnimationBg',
		components: {},

		props: {
			user: null
		},


		data() {
			return {
				isInView: true,
				isAnimating: false,
				// tlpunchline: null,
				pt1: null,
				pt2: null
			}
		},

		mounted() {
			this.$nextTick(() => {
				this.animateIn()
			})
		},

		methods: {

			animateIn() {
				this.isAnimating = true
				// this.tlpunchline = gsap.timeline()
				// let mySplitText = new SplitText(`.punchline`, {type: "words,chars"}),
				// 	chars = mySplitText.chars; //an array of all the divs that wrap each character
				// gsap.set(`.punchline`, {perspective: 400});
				// this.tlpunchline.from(chars, {
				// 	duration: 0.8,
				// 	opacity: 0,
				// 	scale: 0,
				// 	x: "+=80",
				// 	transformOrigin: "0% 50% -50",
				// 	ease: "back",
				// 	stagger: 0.01
				// }, "+=0");

				// Plane Logo
				// gsap.set("#plane-logo", {xPercent: -25, yPercent: -80, transformOrigin: "right 0%", scale: 1, autoAlpha: 1});
				// gsap.set(["#logo-text", "#reg"], {alpha: 0});
				// let ptlogo = gsap.timeline({paused: false})
				//
				// ptlogo.fromTo('#bg--path3', 5, {drawSVG: '0%'}, {drawSVG: '95%', ease: "power3.inOut", immediateRender: true})
				// ptlogo.to("#plane-logo", {
				//     duration: 5,
				//     ease: "power3.inOut",
				//     immediateRender: true,
				//     scale: 1,
				//     motionPath: {
				//         path: "#path-3",
				//         align: "#path-3",
				//         autoRotate: 45
				//     }
				// }, "-=5")
				//     .to(["#logo-text", "#reg"], {duration: 1, alpha: 1})
				//     .from('#punchline', {duration: 0.4, alpha: 0, y: "+=30"})
				// // .from(chars, {duration: 0.8, opacity: 0, scale: 0, x: "+=80", transformOrigin: "0% 50% -50", ease: "back", stagger: 0.01}, "+=0");


				// Plane 1
				gsap.set("#plane-1", {
					xPercent: -100,
					yPercent: 0,
					transformOrigin: "right 0%",
					scale: 1,
					autoAlpha: 1
				});
				this.pt1 = gsap.timeline({})
				this.pt1.fromTo('#bg--path1', 5, {drawSVG: '0%'}, {
					drawSVG: '97.5%',
					ease: "power3.inOut",
					immediateRender: true
				})
				.to("#plane-1", {
					duration: 5,
					ease: "power3.inOut",
					immediateRender: true,
					scale: 1,
					motionPath: {
						path: "#path-1",
						align: "#path-1",
						autoRotate: 45
					}
				}, "-=5")

				// Plane 2
				this.pt2 = gsap.timeline({})
				gsap.set("#plane-2", {
					xPercent: -100,
					yPercent: 0,
					transformOrigin: "right 0%",
					scale: 1,
					autoAlpha: 1
				});
				this.pt2.fromTo('#bg--path2', 5, {drawSVG: '0%'}, {
					drawSVG: '97.5%',
					ease: "power2.inOut",
					immediateRender: true
				})
				.to("#plane-2", {
					duration: 5,
					ease: "power2.inOut",
					immediateRender: true,
					scale: 1,
					motionPath: {
						path: "#path-2",
						align: "#path-2",
						autoRotate: 45
					}
				}, "-=5")
				.call(() => {
					this.isAnimating = false
					// ptlogo.play()
					// tlpunchline.from(chars, {duration: 0.8, opacity: 0, scale: 0, x: "+=80", transformOrigin: "0% 50% -50", ease: "back", stagger: 0.01}, "+=0");
				})

				// MotionPathHelper.create("#plane-1");


			}


		},

		destroyed() {
			window.removeEventListener('scroll', () => {
			})
			// this.tlpunchline.kill()
			this.pt1.kill()
			this.pt2.kill()

		}
	}
</script>

<style lang="scss" scoped>
    svg {
        position : absolute;
        left     : 0;
        top      : 0;
    }
</style>
